@font-face {
  font-family: "Share Tech Mono";
  src: local('sharetechmono.ttf'),
        url('sharetechmono.ttf') format('truetype')
}  

:root {
  --toastify-color-dark:grey  !important;
}

a {
  text-decoration: none !important;
  color: #FFF;
}
a:hover {
   color: whitesmoke !important
}

.background {
  position: absolute;
  top: 60px;
  bottom: 60px;
  left: 0;
  z-index: -1;
  height:90% !important;
  opacity: 0.3;
}

body {
  background-size: cover;
  background-image: 'newBG.png';
  color: white
}
.distribution-header {
  cursor: pointer
  
}

.cybb_button_target {
  color: white;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.video-container {
  width:70% !important;
}

.cybb-link {
  color: white;
  text-decoration: none
}

.col {
  margin-top: 5px !important;
}

.cybb-collapse {
  background-color: grey !important;
  border-color: grey ! important;
}

/* .card {
  background-color: grey !important;
} */
.list-group-item{
  background-color: lavender !important;
}

.App{
  font-family: "Share Tech Mono", sans-serif;
  min-height: 100vh;
  text-align: center;
  color: #fff; 
  background-image:linear-gradient(
    to top,
    rgba(184, 144, 34, 0.1),
    rgba(123, 110, 22, 0.1) 20%,
    rgba(86, 30, 55, 0.1)), url("newBG4.png") !important; 
  background-size: cover;
  margin: 0;
}
 
.App-skeleton{
  font-family: "Share Tech Mono", sans-serif;
  min-height: 100vh;
  text-align: center;
  color: #fff; 
  /* background-image:linear-gradient(
    to top,
    rgba(38, 140, 153, 0.8),
    rgba(38, 140, 153, 0.8) 20%,
    rgba(38, 140, 153, 0.6)), url("cool-background.png") !important; 
  background-size: cover; */
  margin: 0;
}

.App-skeleton {
  font-family: "Share Tech Mono", sans-serif;
  min-height: 100vh;
  text-align: center;
  color: white; 
  background: linear-gradient(to top,rgba(20, 89, 99, 0.3) -10%, #141414 15%, black 30%, black 70%, #141414 85%, rgba(20, 89, 99, 0.3) 110%);
  background-size: cover;
  margin: 0;
}

.App-body {
  /* min-height: 80vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.Page-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.MintContainer {
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
}

.FeatureListContainer {
  text-align: justify;
}

.homePageCYBB {
  margin-left: 10vh;
  margin-right: 10vh
}

.mintContainers {
  margin-bottom: 5vh
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pageLabel {
  font-size: 0.7em;
}

.iKnowCheckbox {
  font-size:0.50em;
  margin: 10px;
}
.iKnowCheckbox input {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 10px;
  height:10px;
}
.iKnowCheckbox label {
  margin: 10px;
}

.btn-info {
  color: white;
  font-family: "Share Tech Mono", sans-serif;
  text-shadow: 1px 1px darkcyan;
}
.btn-custom {
  color: white !important;
  font-family: "Share Tech Mono", sans-serif;
  background-color: darkcyan !important;
  border-color: darkcyan;

}
.delegate-tab {
  position: absolute;
  right : 15vw;
}

.btn-tab.active {
  background-color: darkcyan !important;
}
.btn-tab.active.disabled{
  background-color: darkcyan !important;

}

.btn-tab {
  background-color: darkslategrey !important;
  border-bottom: 1px solid white !important;
  margin-left: 1px;
  margin-right: 1px;
}

.btn-custom:disabled {
  background-color: darkgrey!important;
}


.fade-in-text {
  font-family: "Share Tech Mono", sans-serif;
  font-size: 10px;
  text-shadow: 1px 1px darkcyan;
  display: inline-block;
  animation: fadeIn linear 5s;
  -webkit-animation: fadeIn linear 5s;
  -moz-animation: fadeIn linear 5s;
  -o-animation: fadeIn linear 5s;
  -ms-animation: fadeIn linear 5s;
}

.bi {
  color: white;
  font-family: "Share Tech Mono", sans-serif;
  /* text-shadow: 1px 1px darkcyan; */
}


@keyframes fadeIn {
  0% {opacity:0;}
  50% {opacity: 0.7}
  100% {opacity:0;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:0.5;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:0.5;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:0.5;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:0.5;}
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  input[type='range'] {
    overflow: hidden;
    width: 50vh;
    -webkit-appearance: none;
    background-color: grey;
  }
  
  input[type='range']::-webkit-slider-runnable-track {
    height: 25px;
    -webkit-appearance: none;
    color: darkcyan;
    margin-top: -1px;
  }
  
  input[type='range']::-webkit-slider-thumb {
    width: 30px;
    -webkit-appearance: none;
    height: 30px;
    background: darkgoldenrod;
    box-shadow: -500px 0 0 500px darkcyan;
  }

}

.modal-header {
  align-self: center
}




.collectionInfo .col:not(:last-child) {
  border-right: 1px solid white
}

.collectionInfo .row {
  border-radius: 0.4em;
  border: 1px solid white;
}

.collectionInfo p {
  font-size: xx-small;
  margin-bottom: 0;
}

.traitsInput.container {
  margin-bottom: 25px;
}


.modal-content {
  background-color: gray !important;
  color: white
}

.settingsContainer p {
  margin-bottom:0
}
.settingsContainer p:not(:last-child) {
  margin-top: 10px
}

.table {
  color: white !important
}

.importantMessage {
  color: #dc3545;
}

.Toastify__toast-body{
  white-space: pre-line;
}